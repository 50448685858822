<!-- BarChart.vue -->
<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  beforeDestroy() {
    if (this.$data._chart) {
      this.$data._chart.destroy();
    }
  },
  watch: {
    chartData: {
      handler() {
        if (this.$data._chart) {
          this.$data._chart.destroy();
        }
        this.$nextTick(() => {
          this.renderChart(this.chartData, this.options);
        });
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.$data._chart) {
          this.$data._chart.destroy();
        }
        this.$nextTick(() => {
          this.renderChart(this.chartData, this.options);
        });
      },
      deep: true,
    },
  },
};
</script>