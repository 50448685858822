<!-- BranchAnalysis.vue -->
<template>
  <div
    ref="viewBranchAnalysis"
    class="content-wrapper view-branch-analysis pt-3 p-2"
  >
    <div class="summary-section mt-0 mx-2">
      <!-- Month Picker with Fetch Button -->
      <b-row class="mb-2">
        <b-col cols="12">
          <b-card class="bg-light border-0">
            <b-form-group class="mb-0">
              <b-row align-v="end">
                <!-- Using align-v="end" for vertical alignment -->
                <b-col cols="12" md="4" class="mb-3 mb-md-0">
                  <b-form-group label="มุมมอง:" class="mb-0" label-class="mb-2">
                    <b-form-select
                      v-model="viewType"
                      :options="[
                        { value: 'month', text: 'รายเดือน' },
                        { value: 'year', text: 'รายปี' },
                      ]"
                      class="h-100"
                      :disabled="isLoading || isLoadingRecommendations"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4" class="mb-3 mb-md-0">
                  <b-form-group
                    :label="viewType === 'month' ? 'เลือกเดือน:' : 'เลือกปี:'"
                    class="mb-0"
                    label-class="mb-2"
                  >
                    <b-form-select
                      v-if="viewType === 'month'"
                      v-model="selectedMonth"
                      :options="monthOptions"
                      class="h-100"
                      :disabled="isLoading || isLoadingRecommendations"
                    ></b-form-select>
                    <b-form-select
                      v-else
                      v-model="selectedYear"
                      :options="yearOptions"
                      class="h-100"
                      :disabled="isLoading || isLoadingRecommendations"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4" class="d-flex align-items-stretch">
                  <b-button
                    variant="primary"
                    @click="handleDateChange"
                    :disabled="isLoading || isLoadingRecommendations"
                    class="w-100 d-flex align-items-center justify-content-center py-2"
                  >
                    <b-spinner
                      small
                      v-if="isLoading || isLoadingRecommendations"
                      class="mr-2"
                    ></b-spinner>
                    <span v-else>ดูข้อมูล</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>

      <!-- Summary Cards -->
      <b-overlay :show="isLoading" rounded="sm">
        <b-row>
          <!-- Revenue cards -->
          <b-col cols="12" md="6">
            <b-card class="summary-card mb-3">
              <div class="summary-content">
                <p class="text-body-secondary mb-1">
                  {{
                    viewType === "year" ? "รายได้รวมต่อปี" : "รายได้รวมต่อเดือน"
                  }}
                </p>
                <h5 class="mb-0">
                  {{ formatCurrency(monthlyTotals.totalRevenue) }}
                </h5>
              </div>
            </b-card>
          </b-col>

          <b-col cols="12" md="6">
            <b-card class="summary-card mb-3">
              <div class="summary-content">
                <p class="text-body-secondary mb-1">
                  {{
                    viewType === "year"
                      ? "รายได้เฉลี่ยต่อเดือน"
                      : "รายได้เฉลี่ยต่อวัน"
                  }}
                </p>
                <h5 class="mb-0">
                  {{
                    formatCurrency(
                      viewType === "year"
                        ? monthlyTotals.averageMonthlyRevenue
                        : monthlyTotals.averageDailyRevenue
                    )
                  }}
                </h5>
              </div>
            </b-card>
          </b-col>

          <!-- Patient cards -->
          <b-col cols="12" md="6">
            <b-card class="summary-card mb-3">
              <div class="summary-content">
                <p class="text-body-secondary mb-1">จำนวนผู้ป่วยรวม</p>
                <h5 class="mb-0">{{ monthlyTotals.totalPatients }}</h5>
              </div>
            </b-card>
          </b-col>

          <b-col cols="12" md="6">
            <b-card class="summary-card mb-3">
              <div class="summary-content">
                <p class="text-body-secondary mb-1">
                  {{
                    viewType === "year"
                      ? "ผู้ป่วยเฉลี่ยต่อเดือน"
                      : "ผู้ป่วยเฉลี่ยต่อวัน"
                  }}
                </p>
                <h5 class="mb-0">
                  {{
                    (
                      (viewType === "year"
                        ? monthlyTotals.averageMonthlyPatients
                        : monthlyTotals.averageDailyPatients) || 0
                    ).toFixed(1)
                  }}
                </h5>
              </div>
            </b-card>
          </b-col>

          <!-- Treatment cards - only show in yearly view -->
          <template v-if="viewType === 'year'">
            <b-col cols="12" md="6">
              <b-card class="summary-card mb-3">
                <div class="summary-content">
                  <p class="text-body-secondary mb-1">จำนวนการรักษารวม</p>
                  <h5 class="mb-0">
                    {{ monthlyTotals.totalTreatments }}
                  </h5>
                </div>
              </b-card>
            </b-col>

            <b-col cols="12" md="6">
              <b-card class="summary-card mb-3">
                <div class="summary-content">
                  <p class="text-body-secondary mb-1">การรักษาเฉลี่ยต่อเดือน</p>
                  <h5 class="mb-0">
                    {{
                      (monthlyTotals.averageMonthlyTreatments || 0).toFixed(1)
                    }}
                  </h5>
                </div>
              </b-card>
            </b-col>
          </template>
        </b-row>
      </b-overlay>
    </div>

    <!-- Loading placeholder -->
    <transition name="fade">
      <b-alert
        v-if="isLoadingRecommendations"
        class="ai-loading mx-2 mt-3"
        show
        variant="info"
      >
        <div class="ai-pulse"></div>
        <span>AI กำลังวิเคราะห์ข้อมูล...</span>
      </b-alert>
    </transition>

    <!-- Tabs -->
    <b-card no-body class="mx-2">
      <b-tabs v-model="currentTab" pills card>
        <!-- Treatment Tab -->
        <b-tab title="รายการรักษา" active>
          <b-card-text>
            <transition-group name="recommendation" tag="div" appear>
              <b-card
                v-if="
                  !isLoadingRecommendations && currentTabRecommendations.length
                "
                key="recommendation-card"
                class="mt-2 recommendation-card"
              >
                <div class="ai-badge">
                  <span class="ai-icon">🤖</span> AI Insights
                </div>
                <h5 class="recommendation-title">คำแนะนำการจัดการบริการ</h5>
                <b-list-group flush>
                  <transition-group name="recommendation-item" tag="div" appear>
                    <b-list-group-item
                      v-for="(rec, index) in currentTabRecommendations"
                      :key="rec.title"
                      class="recommendation-item"
                      :style="{ animationDelay: index * 0.1 + 's' }"
                    >
                      <div
                        class="d-flex align-items-start justify-content-between"
                      >
                        <strong>{{ rec.title }}</strong>
                        <!-- <b-button
                          class="ai-generate-btn ml-2"
                          size="sm"
                          @click="generateAIContent(rec)"
                          :disabled="rec.isGenerating"
                        >
                          <b-spinner
                            small
                            v-if="rec.isGenerating"
                            class="mr-1"
                          ></b-spinner>
                          <span>{{
                            rec.isGenerating
                              ? "กำลังสร้าง..."
                              : "AI Content Generator"
                          }}</span>
                        </b-button> -->
                      </div>
                      <p class="mt-2 mb-2">{{ rec.description }}</p>
                      <div
                        v-if="rec.stats"
                        class="stats-box mt-2 p-2 bg-light rounded"
                      >
                        <small
                          ><strong>ข้อมูลสนับสนุน:</strong>
                          {{ rec.stats }}</small
                        >
                      </div>
                    </b-list-group-item>
                  </transition-group>
                </b-list-group>
              </b-card>
            </transition-group>
            <b-row v-if="treatmentStats.length">
              <b-col md="6" class="mb-4">
                <h4 class="mb-3">สัดส่วนรายได้ตามประเภทการรักษา</h4>
                <div class="chart-container">
                  <pie-chart
                    :key="`treatment-pie-${chartKey}`"
                    :chart-data="treatmentChartData"
                    :options="treatmentChartOptions"
                  />
                </div>
              </b-col>
              <b-col md="6">
                <h4 class="mb-3">สถิติการรักษา</h4>
                <b-table
                  :items="treatmentStats"
                  :fields="treatmentTableFields"
                  striped
                  hover
                  responsive
                >
                  <template #cell(revenue)="data">
                    {{ formatCurrency(data.value) }}
                  </template>
                  <template #cell(percentage)="data">
                    {{ data.value.toFixed(1) }}%
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <p v-else>ไม่มีข้อมูลการรักษา</p>
          </b-card-text>
        </b-tab>

        <!-- Revenue Tab -->
        <b-tab title="รายได้">
          <b-card-text>
            <transition-group name="recommendation" tag="div" appear>
              <b-card
                v-if="
                  !isLoadingRecommendations && currentTabRecommendations.length
                "
                key="recommendation-card"
                class="mt-2 recommendation-card"
              >
                <div class="ai-badge">
                  <span class="ai-icon">🤖</span> AI Insights
                </div>
                <h5 class="recommendation-title">คำแนะนำการจัดการบริการ</h5>
                <b-list-group flush>
                  <transition-group name="recommendation-item" tag="div" appear>
                    <b-list-group-item
                      v-for="(rec, index) in currentTabRecommendations"
                      :key="rec.title"
                      class="recommendation-item"
                      :style="{ animationDelay: index * 0.1 + 's' }"
                    >
                      <div
                        class="d-flex align-items-start justify-content-between"
                      >
                        <strong>{{ rec.title }}</strong>
                        <!-- <b-button
                          class="ai-generate-btn ml-2"
                          size="sm"
                          @click="generateAIContent(rec)"
                          :disabled="rec.isGenerating"
                        >
                          <b-spinner
                            small
                            v-if="rec.isGenerating"
                            class="mr-1"
                          ></b-spinner>
                          <span>{{
                            rec.isGenerating
                              ? "กำลังสร้าง..."
                              : "AI Content Generator"
                          }}</span>
                        </b-button> -->
                      </div>
                      <p class="mt-2 mb-2">{{ rec.description }}</p>
                      <div
                        v-if="rec.stats"
                        class="stats-box mt-2 p-2 bg-light rounded"
                      >
                        <small
                          ><strong>ข้อมูลสนับสนุน:</strong>
                          {{ rec.stats }}</small
                        >
                      </div>
                    </b-list-group-item>
                  </transition-group>
                </b-list-group>
              </b-card>
            </transition-group>
            <div v-if="dailyRevenue.length" class="chart-container">
              <bar-chart
                :key="`revenue-${chartKey}`"
                :chart-data="currentRevenueChartData"
                :options="revenueChartOptions"
              />
            </div>
            <p v-else>ไม่มีข้อมูลรายได้</p>
          </b-card-text>
        </b-tab>

        <!-- Doctors Tab -->
        <b-tab title="ทันตแพทย์">
          <b-card-text v-if="treatmentsByDoctor.length">
            <transition-group name="recommendation" tag="div" appear>
              <b-card
                v-if="
                  !isLoadingRecommendations && currentTabRecommendations.length
                "
                key="recommendation-card"
                class="mt-2 recommendation-card"
              >
                <div class="ai-badge">
                  <span class="ai-icon">🤖</span> AI Insights
                </div>
                <h5 class="recommendation-title">คำแนะนำการจัดการบริการ</h5>
                <b-list-group flush>
                  <transition-group name="recommendation-item" tag="div" appear>
                    <b-list-group-item
                      v-for="(rec, index) in currentTabRecommendations"
                      :key="rec.title"
                      class="recommendation-item"
                      :style="{ animationDelay: index * 0.1 + 's' }"
                    >
                      <div
                        class="d-flex align-items-start justify-content-between"
                      >
                        <strong>{{ rec.title }}</strong>
                        <!-- <b-button
                          class="ai-generate-btn ml-2"
                          size="sm"
                          @click="generateAIContent(rec)"
                          :disabled="rec.isGenerating"
                        >
                          <b-spinner
                            small
                            v-if="rec.isGenerating"
                            class="mr-1"
                          ></b-spinner>
                          <span>{{
                            rec.isGenerating
                              ? "กำลังสร้าง..."
                              : "AI Content Generator"
                          }}</span>
                        </b-button> -->
                      </div>
                      <p class="mt-2 mb-2">{{ rec.description }}</p>
                      <div
                        v-if="rec.stats"
                        class="stats-box mt-2 p-2 bg-light rounded"
                      >
                        <small
                          ><strong>ข้อมูลสนับสนุน:</strong>
                          {{ rec.stats }}</small
                        >
                      </div>
                    </b-list-group-item>
                  </transition-group>
                </b-list-group>
              </b-card>
            </transition-group>
            <div
              v-for="(doctor, index) in treatmentsByDoctor"
              :key="index"
              class="mb-4"
            >
              <b-card class="doctor-card">
                <!-- เพิ่ม class doctor-card -->
                <b-card-title>
                  {{ doctor.doctor }} - รายได้รวม:
                  {{ formatCurrency(doctor.total) }}
                </b-card-title>
                <b-card-sub-title>{{ doctor.specialty }}</b-card-sub-title>
                <div class="chart-container mt-3">
                  <bar-chart
                    v-if="doctor.treatments.length"
                    :key="`doctor-${chartKey}-${index}`"
                    :chart-data="getDoctorChartData(doctor)"
                    :options="doctorChartOptions"
                  />
                  <p v-else>ไม่มีข้อมูลการรักษาสำหรับทันตแพทย์นี้</p>
                </div>
              </b-card>
            </div>
          </b-card-text>
          <p v-else>ไม่มีข้อมูลทันตแพทย์</p>
        </b-tab>

        <!-- Time Tab -->
        <b-tab title="ช่วงเวลา">
          <b-card-text v-if="periodRevenue.length">
            <transition-group name="recommendation" tag="div" appear>
              <b-card
                v-if="
                  !isLoadingRecommendations && currentTabRecommendations.length
                "
                key="recommendation-card"
                class="mt-2 recommendation-card"
              >
                <div class="ai-badge">
                  <span class="ai-icon">🤖</span> AI Insights
                </div>
                <h5 class="recommendation-title">คำแนะนำการจัดการบริการ</h5>
                <b-list-group flush>
                  <transition-group name="recommendation-item" tag="div" appear>
                    <b-list-group-item
                      v-for="(rec, index) in currentTabRecommendations"
                      :key="rec.title"
                      class="recommendation-item"
                      :style="{ animationDelay: index * 0.1 + 's' }"
                    >
                      <div
                        class="d-flex align-items-start justify-content-between"
                      >
                        <strong>{{ rec.title }}</strong>
                        <!-- <b-button
                          class="ai-generate-btn ml-2"
                          size="sm"
                          @click="generateAIContent(rec)"
                          :disabled="rec.isGenerating"
                        >
                          <b-spinner
                            small
                            v-if="rec.isGenerating"
                            class="mr-1"
                          ></b-spinner>
                          <span>{{
                            rec.isGenerating
                              ? "กำลังสร้าง..."
                              : "AI Content Generator"
                          }}</span>
                        </b-button> -->
                      </div>
                      <p class="mt-2 mb-2">{{ rec.description }}</p>
                      <div
                        v-if="rec.stats"
                        class="stats-box mt-2 p-2 bg-light rounded"
                      >
                        <small
                          ><strong>ข้อมูลสนับสนุน:</strong>
                          {{ rec.stats }}</small
                        >
                      </div>
                    </b-list-group-item>
                  </transition-group>
                </b-list-group>
              </b-card>
            </transition-group>
            <div class="chart-container">
              <bar-chart
                :key="`time-${chartKey}`"
                :chart-data="currentTimeChartData"
                :options="timeChartOptions"
              />
            </div>
          </b-card-text>
          <p v-else>ไม่มีข้อมูลช่วงเวลา</p>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import BarChart from "@/components/chart/BarChart.vue";
import PieChart from "@/components/chart/PieChart.vue";
import moment from "moment";

import { mapGetters, mapActions } from "vuex";
import { sampleData } from "@/mocks/branchAnalysis";

export default {
  name: "BranchAnalysis",

  components: {
    BarChart,
    PieChart,
  },
  watch: {
    currentTab: {
      handler() {
        this.$nextTick(() => {
          this.forceChartRerender();
        });
      },
    },
    viewType: {
      handler(newValue) {
        // Reset data states
        this.isLoading = false;
        this.isLoadingRecommendations = false;
        this.analyticsData = null;
        this.dateRange = null;
        this.chartKey = 0;

        // Reset arrays
        this.dailyRevenue = [];
        this.treatmentsByDoctor = [];
        this.periodRevenue = [];
        this.treatmentStats = [];

        // Reset monthlyTotals
        this.$set(this, "monthlyTotals", this.getDefaultMonthlyTotals());

        // Reset response recommendations
        this.$set(this.response, "recommendations", {
          revenue: [],
          doctors: [],
          time: [],
          treatments: [],
        });

        // Reset chart data
        this.$set(this.currentRevenueChartData, "labels", []);
        this.currentRevenueChartData.datasets.forEach(dataset => {
          dataset.data = [];
        });

        this.$set(this.currentTimeChartData, "labels", []);
        this.currentTimeChartData.datasets.forEach(dataset => {
          dataset.data = [];
        });

        // Reset selection based on view type
        if (newValue === "month") {
          this.selectedMonth = moment().format("YYYY-MM");
        } else {
          this.selectedYear = moment().format("YYYY");
        }
      },
    },
  },

  data() {
    return {
      isLoading: false,
      isLoadingRecommendations: false,
      analyticsData: null,
      response: {
        recommendations: {
          revenue: [],
          doctors: [],
          time: [],
          treatments: [],
        },
      },
      viewType: "month", // Add this new property
      selectedYear: moment().format("YYYY"), // Add this
      yearOptions: this.getLastThreeYears(), // Add this
      selectedMonth: moment().format("YYYY-MM"),
      monthOptions: this.getLastTwelveMonths(),
      dateRange: null,

      currentTab: 0,
      chartKey: 0,
      // Initialize with empty states
      monthlyTotals: this.getDefaultMonthlyTotals(),
      dailyRevenue: [],
      treatmentsByDoctor: [],
      periodRevenue: [],
      treatmentStats: [],
      treatmentTableFields: [
        { key: "name", label: "การรักษา" },
        { key: "count", label: "จำนวนครั้ง" },
        { key: "revenue", label: "รายได้" },
        { key: "percentage", label: "% ของรายได้" },
      ],
      currentRevenueChartData: {
        labels: [],
        datasets: [
          {
            label: "รายได้",
            backgroundColor: "#8884d8",
            data: [],
          },
          {
            label: "รายได้เฉลี่ย/วัน",
            backgroundColor: "#82ca9d",
            data: [],
          },
        ],
      },
      ccurrentTimeChartData: {
        labels: [],
        datasets: [
          {
            label: "รายได้เฉลี่ย/วัน",
            backgroundColor: "#8884d8",
            yAxisID: "revenue",
            data: [],
          },
          {
            label: "จำนวนครั้ง",
            backgroundColor: "#82ca9d",
            yAxisID: "count",
            data: [],
          },
        ],
      },
    };
  },

  created() {},

  computed: {
    startDt() {
      if (this.viewType === "year") {
        return moment(this.selectedYear).startOf("year").format();
      }
      return this.selectedMonth
        ? moment(this.selectedMonth).startOf("month").format()
        : "";
    },
    endDt() {
      if (this.viewType === "year") {
        return moment(this.selectedYear).endOf("year").format();
      }
      return this.selectedMonth
        ? moment(this.selectedMonth).endOf("month").format()
        : "";
    },

    recommendations() {
      // Use recommendations from API/mock data instead of calling getRecommendations
      return this.response?.recommendations || {};
    },
    currentTabRecommendations() {
      const tabMapping = {
        0: "treatments",
        1: "revenue",
        2: "doctors",
        3: "time",
      };
      const currentTabType = tabMapping[this.currentTab];
      const recommendations =
        this.response?.recommendations?.[currentTabType] || [];

      return recommendations.map(rec => ({
        ...rec,
        stats: this.formatRecommendationStats(rec.stats),
      }));
    },
    revenueChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                callback: value => `${value / 1000}k`,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                callback: value => {
                  return value;
                },
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const label = data.datasets[tooltipItem.datasetIndex].label;
              const value = tooltipItem.yLabel;
              return `${label}: ${this.formatCurrency(value)}`;
            },
          },
        },
      };
    },

    timeChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              id: "revenue",
              type: "linear",
              position: "left",
              ticks: {
                callback: value => `${value / 1000}k`,
              },
              scaleLabel: {
                display: true,
                labelString: "รายได้เฉลี่ย/วัน (บาท)",
              },
            },
            {
              id: "visits",
              type: "linear",
              position: "right",
              gridLines: {
                drawOnChartArea: false,
              },
              ticks: {
                min: 0,
                stepSize: 0.5,
              },
              scaleLabel: {
                display: true,
                labelString: "จำนวนการรักษาเฉลี่ย/วัน",
              },
            },
          ],
        },
        tooltips: {
          mode: "index",
          intersect: false,
          callbacks: {
            label: (tooltipItem, data) => {
              const label = data.datasets[tooltipItem.datasetIndex].label;
              const value = tooltipItem.yLabel;
              if (label.includes("รายได้")) {
                return `${label}: ${this.formatCurrency(value)}`;
              }
              return `${label}: ${value.toFixed(1)} ครั้ง`; // Changed from คน to ครั้ง
            },
          },
        },
      };
    },
    doctorChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                maxRotation: 45,
                minRotation: 45,
                fontSize: 11,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              id: "revenue",
              type: "linear",
              position: "left",
              ticks: {
                callback: value => `${value / 1000}k`,
                fontSize: 11,
              },
            },
            {
              id: "count",
              type: "linear",
              position: "right",
              ticks: {
                fontSize: 11,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        layout: {
          padding: {
            top: 20,
            bottom: 20,
            left: 10,
            right: 10,
          },
        },
      };
    },
    treatmentChartData() {
      // Default empty chart data structure
      const emptyChartData = {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      };

      // Return default structure if no data
      if (!this.treatmentStats.length) return emptyChartData;

      console.log("treatmentStats", this.treatmentStats);
      // Return actual data if available
      return {
        labels: this.treatmentStats.map(item => item.name),
        datasets: [
          {
            backgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56",
              "#4BC0C0",
              "#9966FF",
              "#FF9F40",
            ],
            data: this.treatmentStats.map(item => item.revenue),
          },
        ],
      };
    },

    treatmentChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "right",
          labels: {
            padding: 20,
            boxWidth: 15,
          },
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const total = dataset.data.reduce((acc, val) => acc + val, 0);
              const value = dataset.data[tooltipItem.index];
              const percentage = ((value / total) * 100).toFixed(1);
              const label = data.labels[tooltipItem.index];
              return `${label}: ${this.formatCurrency(value)} (${percentage}%)`;
            },
          },
        },
      };
    },
  },

  methods: {
    ...mapActions({
      fetchIncomeAnalysis: "moduleAiAnalysis/fetchIncomeAnalysis",
      fetchIncomeRecommendations: "moduleAiAnalysis/fetchIncomeRecommendations", // Add this new action
    }),
    getLastTwelveMonths() {
      moment.locale("th");
      const options = [];
      for (let i = 0; i < 12; i++) {
        const date = moment().subtract(i, "months");
        options.unshift({
          value: date.format("YYYY-MM"),
          text: date.format("MMMM YYYY"),
        });
      }
      return options;
    },
    getLastThreeYears() {
      const years = [];
      const currentYear = moment().year();
      for (let i = 0; i < 3; i++) {
        const year = currentYear - i;
        years.unshift({
          value: year.toString(),
          text: `ปี ${year + 543}`, // Convert to Buddhist year
        });
      }
      return years;
    },
    handleMonthChange() {
      const [year, month] = this.selectedMonth.split("-");
      const date = new Date(year, parseInt(month) - 1, 1);
      this.dateRange = date;
    },
    async handleDateChange() {
      try {
        this.isLoading = true;
        this.isLoadingRecommendations = false;
        this.clearAnalyticsData();
        this.clearRecommendations();

        if (this.viewType === "year") {
          await this.fetchYearlyData();
        } else {
          await this.fetchAnalyticsData();
        }

        this.isLoadingRecommendations = true;
        this.fetchRecommendationsData();
      } catch (error) {
        console.error("Error in handleDateChange:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchAnalyticsData() {
      try {
        const response = await this.fetchIncomeAnalysis({
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          startDt: this.startDt,
          endDt: this.endDt,
        });

        if (response?.data?.analysisResult) {
          const transformedData = this.transformAnalyticsData(
            response.data.analysisResult
          );

          if (transformedData) {
            this.analyticsData = transformedData;
            // Use Vue's $set for reactivity when updating multiple properties
            Object.keys(transformedData).forEach(key => {
              this.$set(this, key, transformedData[key]);
            });

            this.updateChartData();
            await this.forceChartRerender();
          }
        }
      } catch (error) {
        console.error("Error fetching analytics data:", error);
        // Reset to default states on error
        this.clearAnalyticsData();
        throw error;
      }
    },
    async fetchYearlyData() {
      try {
        const response = await this.fetchIncomeAnalysis({
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          startDt: moment(this.selectedYear).startOf("year").format(),
          endDt: moment(this.selectedYear).endOf("year").format(),
          type: "yearly",
        });

        if (response?.data?.analysisResult) {
          const transformedData = this.transformAnalyticsData(
            response.data.analysisResult
          );
          this.analyticsData = transformedData;
          Object.assign(this, transformedData);
          this.updateChartData();
          await this.forceChartRerender();
        }
      } catch (error) {
        console.error("Error fetching yearly data:", error);
        throw error;
      }
    },

    async fetchRecommendationsData() {
      if (!this.analyticsData) return;

      try {
        const response = await this.fetchIncomeRecommendations({
          analyticsData: this.analyticsData,
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
        });

        if (response?.data?.recommendations) {
          // Use $set to ensure reactivity
          this.$set(
            this.response,
            "recommendations",
            response.data.recommendations
          );
        }
      } catch (error) {
        console.error("Error fetching recommendations:", error);
        // Clear recommendations on error
        this.clearRecommendations();
      } finally {
        this.isLoadingRecommendations = false;
      }
    },

    // useMockData() {
    //   if (sampleData.analysisResult) {
    //     const { recommendations, ...otherData } = sampleData.analysisResult;
    //     Object.assign(this, otherData);
    //     this.response = { recommendations };
    //   }
    // },

    formatRecommendationStats(stats) {
      if (!stats) return "ไม่มีข้อมูล";

      let formattedValue = stats.value;
      if (typeof stats.value === "number") {
        if (stats.title.toLowerCase().includes("รายได้")) {
          formattedValue = this.formatCurrency(stats.value);
        } else {
          formattedValue = `${stats.value}${
            stats.unit ? " " + stats.unit : ""
          }`;
        }
      }

      if (stats.treatment) {
        return `${stats.title}: ${formattedValue} (${stats.treatment})`;
      }
      return `${stats.title}: ${formattedValue}`;
    },

    updateTreatmentStats() {
      // Collect all treatments
      const allTreatments = this.treatmentsByDoctor.reduce((acc, doctor) => {
        doctor.treatments.forEach(treatment => {
          const existing = acc.find(t => t.name === treatment.name);
          if (existing) {
            existing.count += treatment.count;
            existing.revenue += treatment.revenue;
          } else {
            acc.push({ ...treatment });
          }
        });
        return acc;
      }, []);

      // Calculate total revenue
      const totalRevenue = allTreatments.reduce((sum, t) => sum + t.revenue, 0);

      // Calculate percentages and sort by revenue
      this.treatmentStats = allTreatments
        .map(treatment => ({
          ...treatment,
          percentage: (treatment.revenue / totalRevenue) * 100,
        }))
        .sort((a, b) => b.revenue - a.revenue);
    },

    updateChartData() {
      if (this.viewType === "year") {
        this.updateYearlyChartData();
      } else {
        this.updateMonthlyChartData();
      }
    },
    updateMonthlyChartData() {
      this.updateRevenueChartData();
      this.updateTimeChartData();
    },

    updateRevenueChartData() {
      if (!this.dailyRevenue || !Array.isArray(this.dailyRevenue)) {
        // Set default empty chart data if dailyRevenue is not valid
        this.currentRevenueChartData = {
          labels: [],
          datasets: [
            {
              label: "รายได้",
              backgroundColor: "#8884d8",
              data: [],
            },
            {
              label: "รายได้เฉลี่ย/วัน",
              backgroundColor: "#82ca9d",
              data: [],
            },
          ],
        };
        return;
      }

      this.currentRevenueChartData = {
        labels: this.dailyRevenue.map(item => item.day),
        datasets: [
          {
            label: "รายได้",
            backgroundColor: "#8884d8",
            data: this.dailyRevenue.map(item => item.revenue),
          },
          {
            label: "รายได้เฉลี่ย/วัน",
            backgroundColor: "#82ca9d",
            data: this.dailyRevenue.map(item => item.avgRevenue),
          },
        ],
      };
    },

    updateTimeChartData() {
      if (!this.periodRevenue || !Array.isArray(this.periodRevenue)) {
        // Set default empty chart data if periodRevenue is not valid
        this.currentTimeChartData = {
          labels: [],
          datasets: [
            {
              label: "รายได้เฉลี่ย/วัน",
              backgroundColor: "#8884d8",
              yAxisID: "revenue",
              data: [],
            },
            {
              label: "จำนวนการรักษาเฉลี่ย/วัน",
              backgroundColor: "#82ca9d",
              yAxisID: "visits",
              data: [],
            },
          ],
        };
        return;
      }

      this.currentTimeChartData = {
        labels: this.periodRevenue.map(item => item.period),
        datasets: [
          {
            label: "รายได้เฉลี่ย/วัน",
            backgroundColor: "#8884d8",
            yAxisID: "revenue",
            data: this.periodRevenue.map(item => item.avgRevenue),
          },
          {
            label: "จำนวนการรักษาเฉลี่ย/วัน",
            backgroundColor: "#82ca9d",
            yAxisID: "visits",
            data: this.periodRevenue.map(item => item.avgVisits),
          },
        ],
      };
    },

    forceChartRerender() {
      this.chartKey += 1;
      this.updateChartData();
    },

    formatCurrency(value) {
      return new Intl.NumberFormat("th-TH", {
        style: "currency",
        currency: "THB",
      }).format(value);
    },

    getDoctorChartData(doctor) {
      return {
        labels: doctor.treatments.map(item => item.name),
        datasets: [
          {
            label: "รายได้",
            backgroundColor: "#8884d8",
            yAxisID: "revenue",
            data: doctor.treatments.map(item => item.revenue),
          },
          {
            label: "จำนวนการรักษาทั้งหมด", // Changed label
            backgroundColor: "#82ca9d",
            yAxisID: "count",
            data: doctor.treatments.map(item => item.totalVisits || item.count), // Use totalVisits if available, fallback to count
          },
        ],
      };
    },
    getDefaultMonthlyTotals() {
      if (this.viewType === "year") {
        return {
          totalRevenue: 0,
          averageMonthlyRevenue: 0,
          totalPatients: 0,
          totalTreatments: 0,
          averageMonthlyPatients: 0,
          averageMonthlyTreatments: 0,
        };
      }
      return {
        totalRevenue: 0,
        averageDailyRevenue: 0,
        totalPatients: 0,
        averageDailyPatients: 0,
      };
    },
    clearAnalyticsData() {
      this.analyticsData = null;
      this.monthlyTotals = this.getDefaultMonthlyTotals();
      this.dailyRevenue = [];
      this.treatmentsByDoctor = [];
      this.periodRevenue = [];
      // Clear treatmentStats directly, no calculation needed
      this.treatmentStats = [];

      // Rest of the method remains the same...
    },

    // New method to clear recommendations
    clearRecommendations() {
      // Clear recommendations with proper Vue reactivity
      this.$set(this.response, "recommendations", {
        revenue: [],
        doctors: [],
        time: [],
        treatments: [],
      });
    },
    transformAnalyticsData(data) {
      if (!data) return null;

      const transformed = { ...data };

      if (this.viewType === "year") {
        // For yearly data, use yearlyTotals directly
        transformed.monthlyTotals =
          data.yearlyTotals || this.getDefaultMonthlyTotals();

        // Transform monthlyRevenue data if it exists
        transformed.dailyRevenue = Array.isArray(data.monthlyRevenue)
          ? data.monthlyRevenue.map(item => ({
              day: item.month,
              revenue: item.revenue || 0,
              avgRevenue: item.avgRevenue || 0,
              patients: item.patients || 0,
              treatments: item.treatments || 0,
              daysInMonth: item.daysInMonth || 30,
            }))
          : [];
      } else {
        // For monthly data, use existing format with defaults
        transformed.monthlyTotals =
          data.monthlyTotals || this.getDefaultMonthlyTotals();
        transformed.dailyRevenue = Array.isArray(data.dailyRevenue)
          ? data.dailyRevenue
          : [];
      }

      // Ensure other properties are arrays even if empty
      transformed.treatmentsByDoctor = Array.isArray(data.treatmentsByDoctor)
        ? data.treatmentsByDoctor
        : [];

      transformed.periodRevenue = Array.isArray(data.periodRevenue)
        ? data.periodRevenue
        : [];

      transformed.treatmentStats = Array.isArray(data.treatmentStats)
        ? data.treatmentStats
        : [];

      return transformed;
    },
    formatMonthlyDataForCharts(monthlyRevenue) {
      if (!monthlyRevenue) return [];

      return monthlyRevenue.map(month => ({
        label: month.month,
        revenue: month.revenue,
        avgRevenue: month.avgRevenue,
        patients: month.patients,
        treatments: month.treatments,
        daysInMonth: month.daysInMonth,
      }));
    },
    updateYearlyChartData() {
      // For revenue chart
      const monthlyData = this.formatMonthlyDataForCharts(
        this.analyticsData.monthlyRevenue
      );

      this.currentRevenueChartData = {
        labels: monthlyData.map(item => item.label),
        datasets: [
          {
            label: "รายได้",
            backgroundColor: "#8884d8",
            data: monthlyData.map(item => item.revenue),
          },
          {
            label: "รายได้เฉลี่ย/วัน",
            backgroundColor: "#82ca9d",
            data: monthlyData.map(item => item.avgRevenue),
          },
        ],
      };

      // For time chart, use periodRevenue
      this.currentTimeChartData = {
        labels: this.periodRevenue.map(item => item.period),
        datasets: [
          {
            label: "รายได้เฉลี่ย/เดือน",
            backgroundColor: "#8884d8",
            yAxisID: "revenue",
            data: this.periodRevenue.map(item => item.avgRevenue),
          },
          {
            label: "จำนวนการรักษา/เดือน",
            backgroundColor: "#82ca9d",
            yAxisID: "visits",
            data: this.periodRevenue.map(item => item.avgVisits),
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.chart-container {
  position: relative;
  height: 400px;
}

/* Override some Bootstrap Vue styles */
.card-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.card-subtitle {
  color: #6c757d;
  margin-bottom: 1rem;
}

.summary-section {
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 0.5rem;
}

.month-picker {
  border: none;
  background-color: #f8f9fa;
}

.summary-card {
  height: 100%;
  border: 1px solid #e9ecef;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.summary-content {
  padding: 0.5rem;
}

.text-body-secondary {
  font-size: 0.875rem;
  color: #6c757d;
}

h5 {
  font-size: 1.15rem;
  font-weight: 600;
}

/* AI Loading Animation */
.ai-loading {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: linear-gradient(135deg, #e3f2fd, #bbdefb);
  border: none;
  border-radius: 8px;
}

.ai-pulse {
  width: 20px;
  height: 20px;
  background: #1976d2;
  border-radius: 50%;
  animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.8;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.5;
  }
}

/* AI Badge */
.ai-badge {
  position: absolute;
  top: -12px;
  right: 20px;
  background: linear-gradient(135deg, #2196f3, #1976d2);
  color: white;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 0.85rem;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(10px);
  animation: badgeAppear 0.5s ease forwards;
  animation-delay: 0.3s;
}

.ai-icon {
  display: inline-block;
  animation: wave 1.5s ease-in-out infinite;
}

@keyframes wave {
  0%,
  100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(15deg);
  }
}

@keyframes badgeAppear {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Card Appearance Animation */
.recommendation-card {
  position: relative;
  transform-origin: top center;
  animation: cardAppear 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
}

@keyframes cardAppear {
  0% {
    opacity: 0;
    transform: scale(0.9) translateY(30px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

/* Item Animations */
.recommendation-item {
  animation: slideIn 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  opacity: 0;
  transform: translateX(-20px);
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Enhanced Hover Effects */
.recommendation-card {
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 6px -1px rgba(33, 150, 243, 0.1),
    0 2px 4px -1px rgba(33, 150, 243, 0.06);
}

.recommendation-card:hover {
  box-shadow: 0 10px 15px -3px rgba(33, 150, 243, 0.2),
    0 4px 6px -2px rgba(33, 150, 243, 0.1);
}

.recommendation-item {
  transition: all 0.3s ease-in-out;
  border-left: 3px solid transparent;
  background: white;
}

.recommendation-item:hover {
  border-left-color: #2196f3;
  background: linear-gradient(to right, rgba(33, 150, 243, 0.05), transparent);
  transform: translateX(5px);
}

.stats-box {
  border-left: 3px solid #1976d2;
  background-color: rgba(33, 150, 243, 0.05);
  transition: all 0.3s ease;
}

.stats-box:hover {
  background-color: rgba(33, 150, 243, 0.1);
  border-left-color: #1565c0;
}

/* Title Animation */
.recommendation-title {
  animation: titleAppear 0.5s ease forwards;
  animation-delay: 0.2s;
  opacity: 0;
  transform: translateY(10px);
}

@keyframes titleAppear {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/* เพิ่ม CSS rules */
.doctor-card {
  margin-bottom: 2rem;
  /* เพิ่ม spacing ระหว่าง card */
}

.chart-container {
  position: relative;
  height: 400px;
  margin: 1rem 0;
  /* ควบคุมขนาดและ margin ของ container */
}

/* เพิ่มกรณีที่มีหลาย chart */
.doctor-card + .doctor-card {
  margin-top: 3rem; /* เพิ่มระยะห่างระหว่าง card */
}
.recommendation-item {
  position: relative;
}

.ai-generate-btn {
  background: linear-gradient(135deg, #7c3aed, #5b21b6);
  border: none;
  color: white;
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 0.8rem;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(124, 58, 237, 0.2);
  min-width: 85px;
  opacity: 0;
  visibility: hidden;
}

.recommendation-item:hover .ai-generate-btn {
  opacity: 1;
  visibility: visible;
}

.ai-generate-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  animation: shine 2s infinite;
}

.ai-generate-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 3px 6px rgba(124, 58, 237, 0.3);
  background: linear-gradient(135deg, #6d28d9, #4c1d95);
}

.ai-generate-btn:disabled {
  background: linear-gradient(135deg, #a78bfa, #8b5cf6);
  transform: none;
}

@keyframes shine {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}
</style>